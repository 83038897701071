import { appConfig } from '../../../../appConfig';
import { Cloudinary } from '../../../../utility/cloudinary';
import { isAttributeNicotine } from '../../utils/productUtils';
import { urlFriendlySlug } from '../../utils/sanitizeUtils';
import { trimEnd } from '../../utils/stringUtils';

const mapNavCategory = (navCategory: ApiLoopNavCategory): ApiProductSection[] => {
    const categories: ApiProductSection[] = [];

    if (navCategory) {
        // since the categories are upside down, recurse into children first
        if (navCategory.superCategories && navCategory.superCategories.length) {
            const childCategories = mapNavCategory(navCategory.superCategories[0]);
            for (let i = 0; i < childCategories.length; i += 1) {
                categories.push({
                    id: childCategories[i].id,
                    name: childCategories[i].name,
                    escapedName: childCategories[i].escapedName,
                });
            }
        }
        categories.push({
            id: parseInt(navCategory.code, 10),
            name: navCategory.name,
            escapedName: urlFriendlySlug(navCategory.name),
        });
    }

    return categories;
};

const resolveSections = (navCategories: ApiLoopNavCategory[]): ApiProductSection[][] => {
    const categoryList: ApiProductSection[][] = [];

    if (navCategories) {
        for (let i = 0; i < navCategories.length; i += 1) {
            const categories = mapNavCategory(navCategories[i]);
            categoryList.push(categories);
        }
    }

    categoryList.sort((a, b) => {
        if (a.length > b.length) return -1;
        if (a.length === b.length) return 0;
        return 1;
    });

    return categoryList;
};

const resolveProductUrl = (
    productData: { name: string; id: string },
    categoryList: ApiProductSection[][],
) => {
    if (!productData.name) {
        // eslint-disable-next-line no-console
        console.warn(`Incomplete product - name missing, product id: ${productData.id}`);
    }

    if (
        categoryList &&
        categoryList.length > 0 &&
        categoryList[0].some((category) => !category.escapedName)
    ) {
        // eslint-disable-next-line no-console
        console.warn(
            `Incomplete navCategory, product id: ${productData.id}, product name: ${productData.name}`,
        );
    }

    let productSlug = urlFriendlySlug(productData.name);
    const baseProductUrl = appConfig.coopSettings.ecommerce?.productBaseUrl;
    if (
        categoryList &&
        categoryList.length > 0 &&
        categoryList[0].every((category) => category.escapedName)
    ) {
        const categoryUrl = categoryList[0].map((category) => category.escapedName).join('/');
        productSlug = `${baseProductUrl}${categoryUrl}/${productSlug}-${productData.id}`;
    } else {
        productSlug = `${baseProductUrl}${productSlug}-${productData.id}`;
    }
    return productSlug;
};

const resolveNutritionFacts = (loopNutritionFacts: ApiLoopNutritionFact[]): ApiNutritionFact[] => {
    let nutritionFacts: ApiNutritionFact[] = [];
    if (loopNutritionFacts) {
        nutritionFacts = loopNutritionFacts.map((nutFact) => ({
            name: nutFact.description,
            value: nutFact.amount?.[0],
            unit: nutFact.unit,
            percentageOfDailyIntake: nutFact.percentageOfDailyIntake,
        }));
    }

    return nutritionFacts;
};

const filterBadge = (attr: ApiLoopAccreditedTag) => {
    if (!attr.imageUrl || !attr.code) {
        return false;
    }

    if (attr.code.toLowerCase() === 'NO_PICTOGRAM'.toLowerCase()) {
        return false;
    }

    return true;
};

const resolveBadges = (searchProduct: ApiLoopProduct): ApiProductAttribute[] => {
    const mapAccreditedTag = (attr: ApiLoopAccreditedTag) => ({
        identifier: attr.code,
        name: attr.description,
        priority: attr.priority,
        image: {
            url: attr.imageUrl,
            identifier: Cloudinary.imageIdentifier(attr.imageUrl),
            path: Cloudinary.imagePath(attr.imageUrl),
        },
    });

    const badges: ApiLoopAccreditedTag[] = [];

    if (searchProduct.localProduct) {
        // "local product" badge is directly on product for some reason
        badges.push(searchProduct.localProduct);
    }

    if (searchProduct.newItem) {
        // "ny" badge is directly on product for some reason
        badges.push(searchProduct.newItem);
    }

    if (searchProduct.ageRestriction) {
        // "age restricted" badge is directly on product for some reason
        badges.push(searchProduct.ageRestriction);
    }

    if (searchProduct.consumerInformationSymbolCodes?.length > 0) {
        const consumerInformationBadges = searchProduct.consumerInformationSymbolCodes.filter(
            (badge) => !isAttributeNicotine(badge.code),
        );
        badges.push(...consumerInformationBadges);
    }

    if (searchProduct.accreditedTags?.length > 0) {
        badges.push(...searchProduct.accreditedTags);
    }

    const mappedBadges = badges
        .sort((a, b) => b.priority - a.priority)
        .filter(filterBadge) // descending by priority, highest priority will be rendered first
        .map(mapAccreditedTag);

    return mappedBadges;
};

const resolveHealthBadges = (searchProduct: ApiLoopProduct): ApiProductAttribute[] => {
    const mapAccreditedTag = (attr: ApiLoopAccreditedTag) => ({
        identifier: attr.code,
        name: attr.description,
        priority: attr.priority,
        image: {
            url: attr.imageUrl,
            identifier: Cloudinary.imageIdentifier(attr.imageUrl),
            path: Cloudinary.imagePath(attr.imageUrl),
        },
    });

    if (!searchProduct.healthSafetyLabels) {
        return [];
    }

    return searchProduct.healthSafetyLabels.filter(filterBadge).map(mapAccreditedTag);
};

const resolveVariants = (searchProductVariants?: ApiLoopProductVariant[]) => {
    const variances =
        searchProductVariants?.map<ApiProductVariant>((variant) => ({
            id: variant.code,
            name: variant.name,
            priceData: resolvePriceData(variant.priceData),
            promotionPriceData:
                variant.promotionPriceData && resolvePriceData(variant.promotionPriceData),
            weight: undefined,
        })) || [];

    return variances;
};

const resolvePriceData = (loopPrice?: ApiLoopPriceData) => {
    if (!loopPrice) {
        return undefined;
    }

    const mapped: ApiPriceData = {
        inclTaxPrice: loopPrice?.b2cPrice,
        exclTaxPrice: loopPrice?.b2bPrice,
    };

    return mapped;
};

const resolveConsumerInstructions = (loopConsumerInstructions?: ApiLoopConsumerInstructions) => {
    if (!loopConsumerInstructions) {
        return undefined;
    }

    return {
        storageInstructions: loopConsumerInstructions.storageInstructions,
        usageInstructions: loopConsumerInstructions.usageInstructions,
    } as ApiConsumerInstructions;
};

const resolveReplacementCountries = (loopReplacementCountries?: ApiLoopCountry[]) => {
    if (!loopReplacementCountries) {
        return undefined;
    }

    return loopReplacementCountries.map((country) => {
        return {
            code: country.code,
            value: country.value,
        };
    });
};

const resolveMultiBuyPromoDetails = (
    loopOnlinePromotion: ApiLoopProductPromotion,
    searchProduct: ApiLoopProduct,
) => {
    if (!loopOnlinePromotion.numberOfProductRequired) {
        return undefined;
    }

    const mapped: MultiBuyEcomPromotion = {
        type: 'MULTIBUY',
        productId: searchProduct.id,
        variantId: undefined,
        description: loopOnlinePromotion.message,
        maxUseText: loopOnlinePromotion.maxNumberOfUseWithUnit?.value
            ? loopOnlinePromotion.maxNumberOfUseWithUnit.value +
              (loopOnlinePromotion.maxNumberOfUseWithUnit.unit
                  ? ` ${loopOnlinePromotion.maxNumberOfUseWithUnit.unit.toLowerCase()}`
                  : '')
            : undefined,
        identifier: loopOnlinePromotion.id,
        numberOfProductRequired: loopOnlinePromotion.numberOfProductRequired,
        bundlePrice: {
            inclTaxPrice: loopOnlinePromotion.priceData.b2cPrice,
            exclTaxPrice: loopOnlinePromotion.priceData.b2bPrice,
        },
        isMemberPrice: loopOnlinePromotion.medMeraRequired,
    };

    return mapped;
};
const resolveFixedPricePromoDetails = (
    loopOnlinePromotion: ApiLoopProductPromotion,
    searchProduct: ApiLoopProduct,
) => {
    let unit: 'st' | 'kg' | undefined;

    switch (searchProduct.salesUnit) {
        case 'pieces':
        case 'Styck':
            unit = 'st';
            break;
        case 'Vikt':
        case 'kg':
            unit = 'kg';
            break;
        default:
            break;
    }

    const mapped: FixedEcomPromotion = {
        type: 'FIXED_PRICE',
        identifier: loopOnlinePromotion.id,
        productId: searchProduct.id,
        variantId: undefined,
        isMemberPrice: loopOnlinePromotion.medMeraRequired,
        fixedPrice: {
            inclTaxPrice: loopOnlinePromotion.priceData?.b2cPrice,
            exclTaxPrice: loopOnlinePromotion.priceData?.b2bPrice,
        },
        fixedPiecePrice: {
            inclTaxPrice: loopOnlinePromotion.piecePriceData?.b2cPrice,
            exclTaxPrice: loopOnlinePromotion.piecePriceData?.b2bPrice,
        },
        maxUseText: loopOnlinePromotion.maxNumberOfUseWithUnit?.value
            ? loopOnlinePromotion.maxNumberOfUseWithUnit.value +
              (loopOnlinePromotion.maxNumberOfUseWithUnit.unit
                  ? ` ${loopOnlinePromotion.maxNumberOfUseWithUnit.unit.toLowerCase()}`
                  : '')
            : undefined,
        unit,
    };

    return mapped;
};
const resolvePercantagePromoDetails = (
    loopOnlinePromotion: ApiLoopProductPromotion,
    searchProduct: ApiLoopProduct,
) => {
    if (!loopOnlinePromotion.percentageDiscount) {
        return undefined;
    }

    const mapped: PercentageEcomPromotion = {
        type: 'PERCENTAGE',
        identifier: loopOnlinePromotion.id,
        productId: searchProduct.id,
        variantId: undefined,
        isMemberPrice: loopOnlinePromotion.medMeraRequired,
        percentageDiscount: loopOnlinePromotion.percentageDiscount,
        maxUseText: loopOnlinePromotion.maxNumberOfUseWithUnit?.value
            ? loopOnlinePromotion.maxNumberOfUseWithUnit.value +
              (loopOnlinePromotion.maxNumberOfUseWithUnit.unit
                  ? ` ${loopOnlinePromotion.maxNumberOfUseWithUnit.unit.toLowerCase()}`
                  : '')
            : undefined,
    };

    return mapped;
};

const parsePromoDetails = (
    loopOnlinePromotion: ApiLoopProductPromotion,
    searchProduct: ApiLoopProduct,
) => {
    switch (loopOnlinePromotion.type) {
        case 'MULTI_BUY_FIXED_PRICE':
            return resolveMultiBuyPromoDetails(loopOnlinePromotion, searchProduct);
        case 'PERCENTAGE_DISCOUNT':
            return resolvePercantagePromoDetails(loopOnlinePromotion, searchProduct);
        case 'FIXED_PRICE':
            return resolveFixedPricePromoDetails(loopOnlinePromotion, searchProduct);
        default:
            return undefined;
    }
};

const resolvePromotions = (searchProduct: ApiLoopProduct) => {
    const promos =
        searchProduct.onlinePromotions
            ?.map((loopPromo) => {
                const promoDetails = parsePromoDetails(loopPromo, searchProduct);
                if (promoDetails) {
                    return {
                        ...promoDetails,
                        priority: loopPromo.priority,
                    };
                }

                return undefined;
            })
            .filter(Boolean) || [];

    return promos;
};

const resolveProductBrand = (searchProduct: ApiLoopProduct) => {
    let brand = '';

    if (searchProduct.manufacturerName) {
        brand += `${searchProduct.manufacturerName}.`;
    }

    if (searchProduct.class) {
        brand += ` ${searchProduct.class}.`;
    }

    if (searchProduct.originCountry?.value) {
        brand += ` ${searchProduct.originCountry.value}.`;
    }

    brand = trimEnd(brand, '.');

    return brand;
};
export const isWeightSalesUnit = (salesUnitCode: string) =>
    salesUnitCode === 'gram' || salesUnitCode === 'kilogram';

export const mapLoopProduct = (searchProduct: ApiLoopProduct): ApiProduct => {
    const sections = resolveSections(searchProduct.navCategories);
    const productName = searchProduct.variety
        ? `${searchProduct.name} ${searchProduct.variety}`
        : searchProduct.name;
    const productUrl = resolveProductUrl({ name: productName, id: searchProduct.id }, sections);

    return {
        id: searchProduct.id,
        ean: searchProduct.ean,
        name: productName,
        url: productUrl,
        manufacturer: resolveProductBrand(searchProduct),
        comparativePriceData: resolvePriceData(searchProduct.comparativePriceData),
        comparativeText: searchProduct.comparativePriceText,
        priceUnit: searchProduct.packageSizeUnit,
        priceData: resolvePriceData(searchProduct.piecePriceData),
        promotionPriceData: resolvePriceData(searchProduct.promotionPriceData),
        promos: resolvePromotions(searchProduct),
        details: {
            content: searchProduct.listOfIngredients,
            description: searchProduct.description,
            size: {
                unit: searchProduct.packageSizeUnit,
                packageSizeInformation: searchProduct.packageSizeInformation,
                size: searchProduct.packageSize,
            },
            nutrientInformation: searchProduct.nutrientInformation,
            nutritionFacts: resolveNutritionFacts(searchProduct.nutrientLinks),
            consumerInformationText: searchProduct.consumerInformationText,
            attributes: resolveBadges(searchProduct),
            healthAttributes: resolveHealthBadges(searchProduct),
            healthSafetySignalWordsCode: searchProduct.healthSafetySignalWordsCode || [],
            healthSafetyPrecautionaryStatementText:
                searchProduct.healthSafetyPrecautionaryStatementText || [],
            healthSafetySignalStatementText: searchProduct.healthSafetySignalStatementText || [],
            maximumStorageTemperature: searchProduct.maxStorageTemperature,
            isAgeLimited: !!searchProduct.ageRestriction,
            isNicotineProduct:
                searchProduct.consumerInformationSymbolCodes?.some((badge) =>
                    isAttributeNicotine(badge.code),
                ) || false,
            recycleFeeData: {
                inclTaxPrice:
                    searchProduct.depositData?.b2cPrice > 0
                        ? searchProduct.depositData?.b2cPrice
                        : undefined,
                exclTaxPrice:
                    searchProduct.depositData?.b2bPrice > 0
                        ? searchProduct.depositData?.b2bPrice
                        : undefined,
            },
            alcoholPercentage: searchProduct.percentageOfAlcoholByVolume,
            fromSweden: searchProduct.fromSweden,
            declarationOfOrigin: searchProduct.declarationOfOrigin,
            countryOfOriginCodes: searchProduct.countryOfOriginCodes?.map((item) => item.value),
            catchData: searchProduct.catchData,
            storageStateCode: searchProduct.storageStateCode,
            nonFoodIngredientStatement: searchProduct.nonFoodIngredientStatement,
            pharmaceuticalData: searchProduct.pharmaceuticalData,
            sustainabilityInfo: searchProduct.sustainabilityInfo?.[0],
        },
        historicalPriceData: resolvePriceData(searchProduct.historicalPriceData),
        variants: resolveVariants(searchProduct.variances),
        identifier: searchProduct.id,
        taxRate: 0, // TODO: map
        sections,
        image: {
            url: searchProduct.imageUrl,
            identifier: Cloudinary.imageIdentifier(searchProduct.imageUrl),
            path: Cloudinary.imagePath(searchProduct.imageUrl),
        },
        promotionProductsEans: searchProduct.promotionProductsEans,
        isMagazine: searchProduct.isMagazine,
        consumerInstructions: resolveConsumerInstructions(searchProduct.consumerInstructions),
        compulsoryAdditiveLabelInformation: searchProduct.compulsoryAdditiveLabelInformation,
        replacementCountries: resolveReplacementCountries(searchProduct.replacementCountries),
        originCountry: searchProduct.originCountry,
        variety: searchProduct.variety,
        class: searchProduct.class,
        preparationInstructionsList: searchProduct.preparationInstructionsList || [],
    };
};
