import type { IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { parseISO } from 'date-fns';

import { appInsights } from '../../../../appInsights';
import {
    mapPricesForPromotion,
    oldMapPricesForPromotion,
} from '../../models/domain/ecommerce/promotion/promotionMappers';
/* eslint-disable @typescript-eslint/no-use-before-define */
import type {
    Address,
    Order,
    OrderDeliveryMode,
    OrderEntry,
    OrderRecipeEntry,
    OrderSubscription,
    PromotionResult,
    TaxValue,
    TimeWindow,
    Voucher,
} from '../../models/domain/order';
import { PaymentType, UnknownPaymentType } from '../../models/domain/order';
import type { OrderHistory } from '../../models/domain/orderHistory';
import type { HybrisAddress } from '../../models/hybris/hybrisAddress';
import type {
    HybrisOrder,
    HybrisOrderDeliveryMode,
    HybrisOrderEntry,
    HybrisOrderSubscription,
    HybrisOrderUnit,
} from '../../models/hybris/hybrisOrder';
import type { HybrisOrderHistory, HybrisTimeWindow } from '../../models/hybris/hybrisOrderHistory';
import type { HybrisOrderRecipeEntry } from '../../models/hybris/hybrisOrderRecipeEntry';
import type { HybrisPrice } from '../../models/hybris/hybrisPrice';
import type {
    HybrisPromotion,
    HybrisPromotionResult,
} from '../../models/hybris/hybrisPromotionResult';
import type { HybrisTaxValue } from '../../models/hybris/hybrisTaxValue';
import type { HybrisVoucher } from '../../models/hybris/hybrisVoucher';
import type { PriceData } from '../../models/priceData/priceData';

const mapDeliveryMode = (hDeliveryMode: HybrisOrderDeliveryMode): OrderDeliveryMode => {
    return {
        code: hDeliveryMode.code === 'homedelivery' ? 'homedelivery' : 'pickup',
        deliveryCostPriceData: mapPrice(hDeliveryMode.deliveryCost),
        description: hDeliveryMode.description,
        name: hDeliveryMode.name,
    };
};

const mapTimeWindow = (hTimeWindow: HybrisTimeWindow): TimeWindow => {
    return {
        stopTime: hTimeWindow.stopTime ? parseISO(hTimeWindow.stopTime) : undefined,
        startTime: hTimeWindow.startTime ? parseISO(hTimeWindow.startTime) : undefined,
        endTime: hTimeWindow.endTime ? parseISO(hTimeWindow.endTime) : undefined,
        timeSlotTime: hTimeWindow.timeSlotTime,
    };
};

const mapAddress = (hAddress: HybrisAddress): Address => {
    return {
        lastName: hAddress.lastName,
        firstName: hAddress.firstName,
        town: hAddress.town,
        postalCode: hAddress.postalCode,
        doorCode: hAddress.doorCode,
        street: hAddress.line1,
        phoneNumber: hAddress.phone,
        companyName: hAddress.companyName,
        stairs: hAddress.stairs,
        email: hAddress.email,
    };
};

const mapTax = (hTaxes: HybrisTaxValue): TaxValue => {
    return {
        appliedValue: hTaxes.appliedValue,
        rate: `${Math.trunc(hTaxes.value)}%`,
    };
};

export const mapPaymentMode = (
    hPaymentMode: HybrisPaymentName,
    hSubPaymentInfo: HybrisSubPaymentInfo,
): PaymentType | string => {
    if (hPaymentMode.toLocaleLowerCase() === 'Klarna Checkout'.toLocaleLowerCase()) {
        const klarnaTypes: Record<HybrisSubPaymentInfo, PaymentType> = {
            INVOICE: PaymentType.Klarna_INVOICE,
            INVOICE_BUSINESS: PaymentType.Klarna_INVOICE_BUSINESS,
            CARD: PaymentType.Klarna_CARD,
            ACCOUNT: PaymentType.Klarna_ACCOUNT,
            FIXED_AMOUNT: PaymentType.Klarna_FIXED_AMOUNT,
            DIRECT_DEBIT: PaymentType.Klarna_DIRECT_DEBIT,
            PAY_IN_X: PaymentType.Klarna_PAY_IN_X,
            BANK_TRANSFER: PaymentType.Klarna_BANK_TRANSFER,
            SWISH: PaymentType.Klarna_SWISH,
            PAY_BY_CARD: PaymentType.Klarna_PAY_BY_CARD,
            FIXED_SUM_CREDIT: PaymentType.Klarna_FIXED_SUM_CREDIT,
        };
        return klarnaTypes[hSubPaymentInfo] || UnknownPaymentType;
    }
    if (hPaymentMode === 'Punchout Invoice') {
        return PaymentType.Punchout_INVOICE;
    }

    return hPaymentMode || UnknownPaymentType;
};

/**
 * Mapper from Hybris promotion model to domain one
 */
const mapOrderPromoToEcomPromotion = (
    hPromo: HybrisPromotion,
    unit: HybrisOrderUnit,
    { productId, variantId }: { productId: string; variantId?: string },
) => {
    const base: BaseEcomPromotion = {
        identifier: hPromo.code,
        description: hPromo.description,
        isMemberPrice: hPromo.medmera,
        productId,
        variantId,
    };

    if (hPromo.promotionType === 'PERCENTAGE') {
        return {
            ...base,
            type: 'PERCENTAGE',
            percentageDiscount: hPromo.percentageDiscount,
        } as PercentageEcomPromotion;
    }

    if (hPromo.promotionType === 'MULTIBUY') {
        return {
            ...base,
            type: 'MULTIBUY',
            numberOfProductRequired: hPromo.numberOfProductRequired,
            bundlePrice: {
                inclTaxPrice: hPromo.bundlePrice,
                exclTaxPrice: undefined, // missing in Hybris
            },
        } as MultiBuyEcomPromotion;
    }

    if (hPromo.promotionType === 'FIXED_PRICE') {
        return {
            ...base,
            type: 'FIXED_PRICE',
            fixedPrice: {
                inclTaxPrice:
                    unit?.code === 'gram' ? hPromo.fixedUnitPrice * 1000 : hPromo.fixedUnitPrice,
                exclTaxPrice: undefined, // missing in Hybris
            },
            unit: unit?.code === 'gram' ? 'kg' : undefined,
        } as FixedEcomPromotion;
    }

    if (hPromo.promotionType === undefined) {
        return undefined;
    }

    appInsights.trackException({
        exception: new Error(`Unknown promotion type. Hybris promo: ${hPromo}`),
    } as IExceptionTelemetry);

    return undefined;
};

const mapOrderEntry = (hEntry: HybrisOrderEntry, order: HybrisOrder) => {
    const ecomPromo = hEntry.appliedPromotions?.length
        ? mapOrderPromoToEcomPromotion(hEntry.appliedPromotions[0].promotion, hEntry.unit, {
              productId: hEntry.product.code,
              variantId: hEntry.variance?.code,
          })
        : undefined;

    const piecePrice = mapPrice(hEntry.pickBasePrice || hEntry.product.pickPrice); // pickBasePrice is different from pickprice when product is weighted or have variances
    const totalPrice = mapPrice(hEntry.totalPrice);
    const totalPriceWithoutDiscount = mapPrice(hEntry.totalPriceWithoutDiscount);
    const recycleFeePiecePrice = mapPrice(hEntry.depositData?.deposit);
    const recycleFeeTotalPrice = mapPrice(hEntry.depositData?.totalDeposit);
    const discount = hEntry.discount ? mapPrice(hEntry.discount) : undefined;

    const promoPrices = discount
        ? mapPricesForPromotion({ totalPrice, totalPriceWithoutDiscount })
        : oldMapPricesForPromotion(
              {
                  totalPrice,
                  piecePrice,
              },
              ecomPromo,
          );

    const mapped: OrderEntry = {
        entryNumber: hEntry.entryNumber,
        quantity: hEntry.quantity,
        originalQuantity: hEntry.originalQuantity,
        shippedUnitQuanity: mapShippedQuantity(hEntry.entryNumber, order),
        product: hEntry.product,
        variance: hEntry.variance,
        replacesEntryNumber: hEntry.replacesEntryNumber,
        replaceable: hEntry.replace,
        promo: ecomPromo,
        discount,
        newPiecePrice: promoPrices.newPiecePrice,
        totalPriceWithoutDiscount: promoPrices.totalPrice,
        totalPrice: promoPrices.newTotalPrice,
        piecePrice: promoPrices.piecePrice,
        recycleFeePiecePrice,
        recycleFeeTotalPrice,
        unit: hEntry.unit.code,
    };

    return mapped;
};

const mapShippedQuantity = (entryNumber: number, order: HybrisOrder) => {
    if (!order?.consignments?.length) {
        return 0;
    }
    for (let i = 0; i < order.consignments.length; i += 1) {
        const entry = order.consignments[i].entries.find(
            (e) => e.orderEntry.entryNumber === entryNumber,
        );
        if (entry) {
            return entry.shippedQuantity || 0;
        }
    }
    return 0;
};

export const mapOrderSubscription = (
    hSubscription?: HybrisOrderSubscription,
): OrderSubscription | null => {
    if (!hSubscription) {
        return null;
    }

    return {
        frequency: hSubscription.frequency,
    };
};

export const mapRecipeList = (hRecipeList?: HybrisOrderRecipeEntry[]) => {
    if (!hRecipeList) {
        return undefined;
    }

    return hRecipeList.map<OrderRecipeEntry>((hRecipeEntry) => {
        return {
            calculation: hRecipeEntry.calculation,
            code: hRecipeEntry.code,
            imageUrl: hRecipeEntry.imageUrl,
            name: hRecipeEntry.name,
            portions: hRecipeEntry.portions,
            recipeEntries: hRecipeEntry.recipeEntries.map((entry) => {
                return {
                    common: entry.common,
                    orderEntry: {
                        basePriceData: mapPrice(entry.orderEntry?.basePrice),
                        entryNumber: entry.orderEntry?.entryNumber,
                        originalQuantity: entry.orderEntry?.originalQuantity,
                        partOfRecipe: entry.orderEntry?.partOfRecipe,
                        pickBasePriceData: mapPrice(entry.orderEntry?.pickBasePrice),
                        product: entry.orderEntry?.product,
                        quantity: entry.orderEntry?.quantity,
                        recipeQuantity: entry.orderEntry?.recipeQuantity,
                        replace: entry.orderEntry?.replace,
                        totalPriceData: mapPrice(entry.orderEntry?.totalPrice),
                        updateable: entry.orderEntry?.updateable,
                    },
                    recipeQuantity: entry.recipeQuantity,
                    substituteId: entry.substituteId,
                    substituteProductsAmount: entry.substituteProductsAmount,
                };
            }),
        };
    });
};

const mapOrder = (hOrder: HybrisOrder): Order => {
    const appliedOrderPromotions = hOrder.appliedOrderPromotions.filter(
        (promo) => !promo.fromVoucher,
    );
    return {
        actualDeliveryDate: hOrder.actualDeliveryDate
            ? parseISO(hOrder.actualDeliveryDate)
            : undefined,
        code: hOrder.code,
        appliedOrderPromotions: appliedOrderPromotions?.map((promo) =>
            mapOrderPromotionResult(promo),
        ),
        appliedVouchers: hOrder.appliedVouchers?.map((voucher) =>
            mapVouchersResult(voucher, hOrder.appliedOrderPromotions),
        ),
        extraAmountToBeReserved: hOrder.extraAmountToBeReserved?.value,
        deliveryCostPriceData: mapPrice(hOrder.deliveryCost),
        deliveryMode: mapDeliveryMode(hOrder.deliveryMode),
        deliveryAddress: mapAddress(hOrder.deliveryAddress),
        productDiscountsPriceData: mapPrice(hOrder.productDiscounts),
        totalProductPriceWithDiscounts: mapPrice(hOrder.totalProductPriceWithDiscounts),
        subtotalWithoutDiscountPriceData: mapPrice(hOrder.subtotalWithoutDiscount),
        totalDiscountsPriceData: mapPrice(hOrder.totalDiscounts),
        totalPriceData: mapPrice(hOrder.totalPrice),
        subtotalPriceData: mapPrice(hOrder.subTotal),
        totalPriceWithExtraAmountToReservePriceData: mapPrice(
            hOrder.totalPriceWithExtraAmountToReserve,
        ),
        totalTaxValues: hOrder.totalTaxValues.map(mapTax),
        totalTax: hOrder.totalTax,
        editable: hOrder.editable,
        driverMessage: hOrder.driverMessage,
        cancellable: hOrder.cancellable,
        isPartOfSubscription: hOrder.isPartOfSubscription,
        timeWindow: hOrder.timeWindow ? mapTimeWindow(hOrder.timeWindow) : undefined,
        status: mapOrderStatus(hOrder.statusDisplay),
        paymentModeFormatted: mapPaymentMode(hOrder.paymentMode, hOrder.subPaymentInfo),
        paymentMode: hOrder.paymentMode, // needed for ga in OrderCompletePage
        subPaymentInfo: hOrder.subPaymentInfo, // needed for ga in OrderCompletePage
        store: hOrder.store,
        entries: hOrder.entries.filter(Boolean).map((item) => mapOrderEntry(item, hOrder)),
        totalQuantity: hOrder.totalQuantity,
        productIds: hOrder.entries?.map((x) => x.product.code) ?? [],
        recipeList: mapRecipeList(hOrder.orderRecipeList),
        created: new Date(hOrder.created),
        subscription: mapOrderSubscription(hOrder.subscription),
        pointOfService: hOrder.pointOfService,
        originalOrderCode: hOrder.originalOrderCode,
        editedOrderCode: hOrder.editedOrderCode,
        user: hOrder.user,
        trackingId: hOrder?.consignments?.length ? hOrder.consignments[0].trackingID : undefined,
        stopTime: hOrder.stopTime ? parseISO(hOrder.stopTime) : undefined,
    };
};

const mapPrice = (hybrisPrice?: HybrisPrice): PriceData => {
    return {
        inclTaxPrice: hybrisPrice?.value,
        exclTaxPrice: hybrisPrice?.valueWithoutTax,
    };
};

const mapVouchersResult = (
    hybrisVocher: HybrisVoucher,
    hybrisOrderPromotions: HybrisPromotionResult[],
): Voucher => {
    const orderPromotion = hybrisOrderPromotions.find(
        (o) =>
            o.consumedEntries?.length &&
            o.fromVoucher &&
            o.consumedEntries[0].code === hybrisVocher.voucherCode,
    );
    return {
        voucherCode: hybrisVocher.voucherCode,
        freeShipping: hybrisVocher.freeShipping,
        compensationVoucher: hybrisVocher.compensationVoucher,
        externalReason: hybrisVocher.externalReason,
        priceData: {
            exclTaxPrice: orderPromotion?.totalDiscountWithoutTax,
            inclTaxPrice: hybrisVocher.value,
        },
        name: hybrisVocher.name,
        triggeredPromotion: true,
        notAppliedHint: undefined,
    };
};

const mapOrderPromotionResult = (hybrisPromotion: HybrisPromotionResult): PromotionResult => {
    return {
        description: hybrisPromotion.description || '',
        totalDiscountPriceData: {
            inclTaxPrice: hybrisPromotion.totalDiscount,
            exclTaxPrice: hybrisPromotion.totalDiscountWithoutTax,
        },
    };
};

export const mapOrderStatus = (status: string): OrderStatus => {
    switch (status) {
        case 'ordered':
            return 'ordered';
        case 'planning':
            return 'planning';
        case 'picking':
            return 'picking';
        case 'picked':
            return 'picked';
        case 'cancelled':
        case 'cancelling':
            return 'cancelled';
        default:
            return 'unknown';
    }
};

export const mapOrderHistory = (hOrder: HybrisOrderHistory): OrderHistory | null => {
    if (!hOrder) return null;

    try {
        return {
            active: hOrder.active,
            actualDeliveryDate: parseISO(hOrder.actualDeliveryDate),
            code: hOrder.code,
            editable: hOrder.editable,
            status: mapOrderStatus(hOrder.statusDisplay),
            deliveryMode: {
                code: hOrder.deliveryMode.code === 'homedelivery' ? 'homedelivery' : 'pickup',
            },
            deliveryWindow: {
                startTime: hOrder.deliveryWindow?.startTime
                    ? parseISO(hOrder.deliveryWindow.startTime)
                    : undefined,
                endTime: hOrder.deliveryWindow?.endTime
                    ? parseISO(hOrder.deliveryWindow.endTime)
                    : undefined,
            },
            stopTime: hOrder.stopTime ? parseISO(hOrder.stopTime) : undefined,
            placed: parseISO(hOrder.placed),
            deliveryAddress: hOrder.deliveryAddress,
            pointOfService: hOrder.pointOfService,
            editedOrderCode: hOrder.editedOrderCode,
            partOfSubscription: !!hOrder.partOfSubscription,
            totalPriceData: mapPrice(hOrder.total),
            totalPriceWithExtraAmountToReserve: mapPrice(hOrder.totalPriceWithExtraAmountToReserve),
            cancellable: hOrder.cancellable,
            trackingID: hOrder.trackingID,
        };
    } catch (err) {
        appInsights.trackException(err as IExceptionTelemetry);
        return null;
    }
};

export default mapOrder;
