'use client';

import { useOutsideClick, useSetTimeout } from '@coop/hooks';
import { CheckmarkIcon, Warning1Icon } from '@coop/icons';
import classNames from 'classnames';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { Icon } from '../Icon';
import styles from './Notification.module.scss';

type NotficationThemeType = 'darkGreen' | 'darkPink' | 'lightGreen';

export type NotificationProps = {
    message: string | undefined;
    header?: string;
    autoClose?: boolean;
    close?: () => void;
    show: boolean;
    hideAfterMiliseconds?: number;
    hasOutsideClick?: boolean;
    position?: 'fixed' | 'absolute';
    size?: 'default' | 'productCard';
    bottomSlot?: React.ReactNode;
} & (
    | {
          type: 'alert' | 'success';
      }
    | {
          type: 'custom';
          theme?: NotficationThemeType;
          iconSlot?: React.ReactNode;
      }
);

export const DEFAULT_NOTIFICATION_HIDE_TIME = 2000;

export const Notification = ({
    autoClose = true,
    hasOutsideClick = true,
    hideAfterMiliseconds = DEFAULT_NOTIFICATION_HIDE_TIME,
    position = 'fixed',
    size = 'default',
    ...props
}: React.PropsWithChildren<NotificationProps>) => {
    useSetTimeout(props.show && autoClose && !!props.close, hideAfterMiliseconds, () =>
        props.close?.(),
    );

    const ref = useRef<HTMLElement>(null);

    useOutsideClick(ref, props.close, !!hasOutsideClick && props.show);

    const [msg, setMsg] = useState('');
    // derived state from props is crap, but its the only way
    // the CSS Transitions will work if 'show' is derived from
    // a message existance, i.e. show={!!message}
    useEffect(() => {
        if (props.message) setMsg(props.message);
    }, [props.message]);

    let colorTheme: NotficationThemeType | undefined;

    switch (props.type) {
        case 'alert':
            colorTheme = 'darkPink';
            break;
        case 'success':
            colorTheme = 'darkGreen';
            break;
        case 'custom':
            colorTheme = props.theme;
            break;
        default:
            break;
    }

    return (
        <CSSTransition
            nodeRef={ref}
            in={props.show}
            timeout={{
                appear: 200,
                enter: 200,
                exit: 200,
            }}
            classNames={{
                appearActive: styles['is-open'],
                enterActive: styles['is-open'],
                enterDone: styles['is-open'],
                exitActive: styles['is-hiding'],
            }}
            enter
            appear
            unmountOnExit
        >
            <section
                ref={ref}
                className={classNames(
                    styles.Notification,
                    position && styles[`Notification-position--${position}`],
                )}
            >
                <div
                    className={classNames(
                        styles['Notification-dialog'],
                        size && styles[`Notification-dialog-size--${size}`],
                        colorTheme && styles[`Notification-dialog-theme--${colorTheme}`],
                    )}
                >
                    <div className={styles['Notification-icon']}>
                        {(() => {
                            switch (props.type) {
                                case 'alert':
                                    return <Icon icon={Warning1Icon} color="white" />;
                                case 'success':
                                    return <Icon icon={CheckmarkIcon} color="white" />;
                                case 'custom':
                                    return props.iconSlot;
                                default:
                                    return null;
                            }
                        })()}
                    </div>
                    {props.header && (
                        <p className={styles['Notification-header']}>{props.header}</p>
                    )}
                    {msg && <p>{msg}</p>}
                    {!!props.bottomSlot && (
                        <div className={styles['Notification-bottom']}>{props.bottomSlot}</div>
                    )}
                </div>
            </section>
        </CSSTransition>
    );
};
