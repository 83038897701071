import type { PriceData } from '../../../priceData/priceData';
import type { ProductVariantProps } from '../../../props/itemTeaserProps';

export const isMultiBuyPromotion = (
    promo: EcomPromotion | undefined,
): promo is MultiBuyEcomPromotion => !!promo && promo.type === 'MULTIBUY';

export const isFixedPricePromotion = (
    promo: EcomPromotion | undefined,
): promo is FixedEcomPromotion => !!promo && promo.type === 'FIXED_PRICE';

export const isPercentagePromotion = (
    promo: EcomPromotion | undefined,
): promo is PercentageEcomPromotion => !!promo && promo.type === 'PERCENTAGE';

export const mapPricesForPromotion = ({
    totalPrice,
    totalPriceWithoutDiscount,
}: {
    totalPrice?: PriceData;
    totalPriceWithoutDiscount?: PriceData;
}) => {
    return {
        piecePrice: undefined,
        totalPrice: totalPriceWithoutDiscount,
        newPiecePrice: undefined, // no promo, no price
        newTotalPrice: totalPrice, // no promo, no price
    };
};

/**
 * This is basically a function to correct Hybris API's models. They use the same price fields for different prices depending on promotion.
 * So to make it (hopefuly) easier, we map to our own price model based on that promotion
 * It also shows which exact calculated pricing Hybris is missing for a given promotion
 */
export const oldMapPricesForPromotion = (
    {
        totalPrice,
        piecePrice,
    }: {
        totalPrice?: PriceData;
        piecePrice?: PriceData;
    },
    promo?: EcomPromotion,
) => {
    if (promo && isPercentagePromotion(promo)) {
        return {
            piecePrice,
            totalPrice: undefined, // missing in Hybris
            newPiecePrice: undefined, // missing in Hybris
            newTotalPrice: totalPrice,
        };
    }

    if (promo && isMultiBuyPromotion(promo)) {
        return {
            piecePrice,
            totalPrice,
            newPiecePrice: undefined, // missing in Hybris
            newTotalPrice: undefined, // missing in Hybris
        };
    }

    if (promo && isFixedPricePromotion(promo)) {
        return {
            piecePrice,
            totalPrice: undefined, // missing in Hybris
            newPiecePrice: promo.fixedPrice,
            newTotalPrice: totalPrice,
        };
    }

    return {
        piecePrice,
        totalPrice,
        newPiecePrice: undefined, // no promo, no price
        newTotalPrice: undefined, // no promo, no price
    };
};

export const mapVariantPromotion = (
    promo?: EcomPromotion,
    currentVariant?: ProductVariantProps,
): EcomPromotion | undefined => {
    if (currentVariant && isFixedPricePromotion(promo)) {
        return {
            ...promo,
            fixedPiecePrice: currentVariant.promotionPriceData,
        };
    }
    return promo;
};
