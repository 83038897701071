import getDirectPersonalizationSearchAxiosClient from '../../../search/api/directPersonalizationAxiosClient';
import type {
    ApiLoopRelatedProductsMultipleRequest,
    ApiLoopRelatedProductsRequest,
    ApiLoopResultOptionsRequestBody,
    ApiLoopSearchBaseRequest,
} from '../../../search/api/searchRequests';
import { getCancellationToken } from '../utils';
import getDirectArticleServiceClient from './directArticleServiceClient';
import getDirectDynamicYieldProductClient from './directDynamicYieldProductClient';

const preparePersonalizationRequestParams = (
    storeId: string,
    customerGroups: string[] | undefined,
) => {
    const requestParams = {
        store: storeId,
        groups: customerGroups && !!customerGroups.length ? customerGroups.join(',') : null,
    };

    return requestParams;
};

const preparePersonalizationSearchRequestParams = (
    storeId: string,
    customerGroups: string[] | undefined,
    quickSearch: boolean,
) => {
    const baseParams = preparePersonalizationRequestParams(storeId, customerGroups);

    const requestParams = {
        ...baseParams,
        direct: !!quickSearch,
    };

    return requestParams;
};

const getCurrentProductOffersLoop = async (
    currentPromotionsRequest: ApiLoopSearchBaseRequest,
    storeId: string,
    abortSignal?: AbortSignal,
    customerGroups?: string[],
    onlyPrimary?: boolean,
): Promise<ApiLoopSearchResponse<ApiLoopProduct>> => {
    const token = getCancellationToken(abortSignal);
    const client = getDirectPersonalizationSearchAxiosClient();

    const response = await client.post<ApiLoopSearchResponse<ApiLoopProduct>>(
        '/search/products/promotions',
        currentPromotionsRequest,
        {
            params: {
                ...preparePersonalizationSearchRequestParams(storeId, customerGroups, false),
                'only-primary': onlyPrimary,
            },
            cancelToken: token,
        },
    );
    return response.data;
};

const getProductsForPromotionLoop = async (
    requestBody: ApiLoopResultOptionsRequestBody,
    storeId: string,
    customerGroups?: string[],
): Promise<ApiLoopSearchResponse<ApiLoopProduct>> => {
    const client = getDirectPersonalizationSearchAxiosClient();

    const response = await client.post<ApiLoopSearchResponse<ApiLoopProduct>>(
        '/search/products/promotion',
        requestBody,
        {
            params: preparePersonalizationSearchRequestParams(storeId, customerGroups, false),
        },
    );
    return response.data;
};

const getRelatedProducts = async (
    requestBody: ApiLoopRelatedProductsRequest,
    storeId: string,
    customerGroups?: string[],
): Promise<ApiLoopSearchResponse<ApiLoopProduct>> => {
    const client = getDirectPersonalizationSearchAxiosClient();

    const response = await client.post<ApiLoopSearchResponse<ApiLoopProduct>>(
        '/search/entities/related',
        requestBody,
        {
            params: preparePersonalizationRequestParams(storeId, customerGroups),
        },
    );
    return response.data;
};

export const getRelatedProductsMultipleApiUrl = '/search/products/multiple/related';
const getRelatedProductsMultiple = async (
    requestBody: ApiLoopRelatedProductsMultipleRequest,
    storeId: string,
    customerGroups?: string[],
): Promise<ApiLoopRelatedMultipleResponse<ApiLoopProduct>> => {
    const client = getDirectPersonalizationSearchAxiosClient(false);

    const response = await client.post<ApiLoopRelatedMultipleResponse<ApiLoopProduct>>(
        getRelatedProductsMultipleApiUrl,
        requestBody,
        {
            params: preparePersonalizationRequestParams(storeId, customerGroups),
        },
    );
    return response.data;
};

const getCustomCampaigns = async (
    request: ApiDYCustomCampaignRequest,
    options?: { timeout: number },
) => {
    const client = getDirectDynamicYieldProductClient(options?.timeout || 5000);
    const response = await client.post<ApiDYCustomCampaignResponse>('/search/choose', request, {
        params: { store: '016001', groups: 'CUSTOMER_PRIVATE', direct: false },
    });

    return response.data;
};

export const getRecommendations = async (
    storeId: string,
    request: ApiDYProductRecommendationsRequest,
    customerGroups?: string[],
) => {
    const client = getDirectDynamicYieldProductClient();
    const response = await client.post<ApiDYProductRecommendationsResponse>(
        '/search/choose',
        request,
        { params: preparePersonalizationSearchRequestParams(storeId, customerGroups, false) },
    );

    return response.data;
};

export const getSustainabilityDeclaration = async (productId: string) => {
    const response = await getDirectArticleServiceClient().get<boolean>(
        `/search/articles/sustainability/${productId}`,
    );
    return response.data;
};

export const productApi = {
    getRecommendations,
    getCustomCampaigns,
    getCurrentProductOffersLoop,
    getProductsForPromotionLoop,
    getSustainabilityDeclaration,
    getRelatedProducts,
    getRelatedProductsMultiple,
};
