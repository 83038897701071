import { SessionStorageFacade } from '../../../../facades/storage/sessionStorageFacade';

const SESSION_KEY: string = 'consentShown';

const consentShownSession = {
    get: () => {
        return SessionStorageFacade.instance.getObject<boolean>(SESSION_KEY);
    },
    add: () => {
        SessionStorageFacade.instance.addObject(SESSION_KEY, true);
    },
};

export default consentShownSession;
