/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import { useOutsideClick } from '@coop/hooks';
import classNames from 'classnames';
import * as React from 'react';
import { useContext, useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';
import { CSSTransition } from 'react-transition-group';

import { useRemoveScrollOnFixedElements, useTrapFocus } from './Modal.hooks';
import styles from './Modal.module.scss';
import ModalContextProvider, { ModalContextState } from './ModalContext';

interface BaseModalProps {
    isOpen: boolean;
    close?: () => void;
    afterClose?: () => void;
    alignment?: 'center' | 'left' | 'right';

    position?: 'fixed' | 'absolute';

    /** Only applicable to centered position */
    animation?: 'fade' | 'scale' | 'slideLeft' | 'slideRight';
    toggleTime?: 250 | 500;
    disableOutsideClick?: boolean;

    /**
     * Sets focus on the role="dialog" element when it mounts.
     * If needed, disable that and provide your own initial focus programatically in the Modal's content you create.
     */
    initialFocusOnDialog?: boolean;
    afterOutsideClick?: () => void;
    additionalContainerClasses?: string;
    asideModalContainerStyle?: React.CSSProperties;
    disableOverlay?: boolean;
    renderTrigger?: (ref: React.RefObject<HTMLButtonElement>) => React.ReactNode;
    transitionWithOverlay?: boolean; // If we never want to toggle off the overlay, useful if we load 2 overlays after eachother
}

/**
 * Do not export or use this component directly, always use FlyIn or Modal components
 */
const BaseModal = ({ toggleTime = 500, ...props }: React.PropsWithChildren<BaseModalProps>) => {
    const modalContext = useContext(ModalContextState);
    const triggerRef = React.useRef<HTMLButtonElement>(null);

    const transitionRef = useRef(null);

    return (
        <>
            {props.renderTrigger?.(triggerRef)}
            <MaybePortal usePortal={!modalContext?.parentId}>
                <CSSTransition
                    nodeRef={transitionRef}
                    in={props.isOpen}
                    timeout={{
                        enter: toggleTime,
                        exit: toggleTime,
                    }}
                    classNames={{
                        appearActive: styles['is-visible'],
                        appearDone: styles['is-visible'],
                        enterActive: classNames(
                            styles['is-visible'],
                            props.transitionWithOverlay && styles['Modal--keepOverlay'],
                        ),
                        enterDone: classNames(
                            styles['is-visible'],
                            props.transitionWithOverlay && styles['Modal--keepOverlay'],
                        ),
                        exitActive: classNames(
                            styles['is-hidden'],
                            props.transitionWithOverlay && styles['Modal--keepOverlay'],
                        ),
                    }}
                    onExited={props.afterClose}
                    unmountOnExit={!props.transitionWithOverlay}
                    appear
                    enter
                >
                    <BaseModalContent ref={transitionRef} {...props} triggerRef={triggerRef} />
                </CSSTransition>
            </MaybePortal>
        </>
    );
};

type BaseModalContentProps = React.PropsWithChildren<
    BaseModalProps & { triggerRef: React.RefObject<HTMLButtonElement> }
>;

const BaseModalContent = React.forwardRef<HTMLDivElement, BaseModalContentProps>(
    (
        {
            alignment = 'center',
            position = 'fixed',
            animation = 'scale',
            initialFocusOnDialog = true,
            ...props
        },
        ref,
    ) => {
        const modalContext = useContext(ModalContextState);

        const modalContainerStyles = classNames(
            styles.Modal,
            props.additionalContainerClasses,
            alignment === 'left' && styles['Modal--left'],
            alignment === 'right' && styles['Modal--right'],
            alignment === 'center' && styles['Modal--center'],
            alignment === 'center' && animation === 'scale' && styles['Modal--scaleAnimation'],
            alignment === 'center' && animation === 'fade' && styles['Modal--fadeAnimation'],
            alignment === 'center' &&
                animation === 'slideLeft' &&
                styles['Modal--slideLeftAnimation'],
            alignment === 'center' &&
                animation === 'slideRight' &&
                styles['Modal--slideRightAnimation'],
            position === 'absolute' && styles['Modal--absolute'],
        );

        const handleClose = () => {
            if (!modalContext?.hasOtherModalsAsChildren) {
                // there are no other modals under this current one, we can close current
                // if there are, the parent modal will not close
                props.close?.();
            }
        };

        const asideRef = useTrapFocus<HTMLDivElement>(handleClose);

        useOutsideClick(
            asideRef,
            () => {
                handleClose();
                props.afterOutsideClick?.();
            },
            props.isOpen && !props.disableOutsideClick,
        );

        // This effect need to be called first to have the right activeElement to snapshot
        useLayoutEffect(() => {
            const focusedElementOnMount =
                props.triggerRef.current || (document.activeElement as HTMLElement);
            return () => {
                focusedElementOnMount.focus();
            };
        }, [props.triggerRef]);

        useLayoutEffect(() => {
            // No default focus is provided, so focus on the dialog itself
            if (initialFocusOnDialog) {
                asideRef.current?.focus();
            }
        }, [initialFocusOnDialog, asideRef]);

        return (
            <div ref={ref} className={modalContainerStyles} id={modalContext?.id}>
                <div className={!props.disableOverlay ? styles['Modal-overlay'] : ''} />
                <div
                    ref={asideRef}
                    className={styles['Modal-container']}
                    style={props.asideModalContainerStyle}
                    aria-labelledby={modalContext?.headerId}
                    aria-describedby={modalContext?.descriptionId}
                    role="dialog"
                    aria-modal
                    tabIndex={initialFocusOnDialog ? -1 : undefined}
                >
                    {props.children}
                </div>
            </div>
        );
    },
);

interface ModalProps extends BaseModalProps {
    idForDebug?: string;
    additionalClasses?: string;
}

const Modal = (props: React.PropsWithChildren<ModalProps>) => {
    return (
        <ModalContextProvider idForDebug={props.idForDebug} isOpen={props.isOpen}>
            <BaseModal {...props}>
                {props.position === 'fixed' ? (
                    <ModalRemoveScroll className={props.additionalClasses}>
                        {props.children}
                    </ModalRemoveScroll>
                ) : (
                    <div className={props.additionalClasses}>{props.children}</div>
                )}
            </BaseModal>
        </ModalContextProvider>
    );
};

const MaybePortal = (props: React.PropsWithChildren<{ usePortal: boolean }>) => {
    return props.usePortal ? (
        <Portal selector="div#portal">{props.children}</Portal>
    ) : (
        <>{props.children}</>
    );
};

const Portal = (props: React.PropsWithChildren<{ selector: string }>) => {
    const domNode = document.querySelector(props.selector) || null;

    if (!domNode) {
        // eslint-disable-next-line no-console
        console.error('No portal element found for the Modal');
        return null;
    }

    return ReactDOM.createPortal(props.children, domNode);
};

const ModalRemoveScroll = (props: React.PropsWithChildren<{ className?: string }>) => {
    useRemoveScrollOnFixedElements();

    return (
        <RemoveScroll className={props.className} noIsolation>
            {props.children}
        </RemoveScroll>
    );
};

export default Modal;
