import type { FC, ReactNode } from 'react';
import * as React from 'react';
import { createContext, useMemo } from 'react';

import type { RecipeCategoryFacet } from '../../../search/models/models';

const getSelectedCategories = (
    parentName: string,
    categoryFacets: RecipeCategoryFacet[] | null,
): Record<string, string> => {
    if (categoryFacets === null) {
        return {};
    }
    return categoryFacets.reduce(
        (acc, categoryFacet) => {
            if (categoryFacet.selected) {
                const parentAlreadyHasValue = Boolean(acc[parentName]);
                const newValue = parentAlreadyHasValue
                    ? `${acc[parentName]}+${categoryFacet.item}`
                    : categoryFacet.item;

                return {
                    ...acc,
                    [parentName]: newValue,
                };
            }

            if (Array.isArray(categoryFacet.categories)) {
                return {
                    ...acc,
                    ...getSelectedCategories(categoryFacet.item, categoryFacet.categories),
                };
            }

            return acc;
        },
        {} as Record<string, string>,
    );
};

// mimic old tracking logic
export const getRecipeComboHit = (categories: RecipeCategoryFacet[]) => {
    const selectedCategoryFacets = categories.reduce(
        (acc, categoryFacet) => ({
            ...acc,
            ...getSelectedCategories(categoryFacet.item, categoryFacet.categories),
        }),
        {},
    );

    return Object.entries(selectedCategoryFacets).reduce((acc, [parentName, childrenStr]) => {
        if (acc.length) {
            return `${acc};${parentName}:${childrenStr}`;
        }

        return `${parentName}:${childrenStr}`;
    }, '');
};

const ValueContext = createContext<{ recipeComboHit: string | null }>({
    recipeComboHit: null,
});

export const useRecipeFilterTrackingContext = () => {
    const { recipeComboHit } = React.useContext(ValueContext);

    return {
        recipeComboHit,
    };
};

interface RecipeFilterPageTrackingContextProviderProps {
    categories: RecipeCategoryFacet[];
    children: ReactNode;
}

const RecipeFilterPageTrackingContextProvider: FC<
    React.PropsWithChildren<RecipeFilterPageTrackingContextProviderProps>
> = ({ categories, children }) => {
    const value = useMemo(
        () => ({ recipeComboHit: getRecipeComboHit(categories ?? []) }),
        [categories],
    );

    return <ValueContext.Provider value={value}>{children}</ValueContext.Provider>;
};

export default RecipeFilterPageTrackingContextProvider;
